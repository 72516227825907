const mensajes = {
    // TablaDetalles
    'TablaDetalles.Titulo': 'Detalles',
    'TablaDetalles.CodigoBarras': 'Código barras ítem',
    'TablaDetalles.DescripcionItem': 'Descripción ítem',
    'TablaDetalles.Lote': 'Lote',
    'TablaDetalles.Peso': 'Peso',
    'TablaDetalles.PesoFraccionSolicitado': 'Peso Solicitado',
    'TablaDetalles.PesoFraccionRecibido': 'Peso Recibido',
    'TablaDetalles.FechaVencimiento': 'Fecha vencimiento',
    'TablaDetalles.FechaIngreso': 'Fecha ingreso',
    'TablaDetalles.DescripcionDivisionOrigen': 'División origen',
    'TablaDetalles.DescripcionDivisionDestino': 'División destino',
    'TablaDetalles.CantidadSolicitada': 'Cantidad solicitada',
    'TablaDetalles.CantidadRecibida': 'Cantidad recibida',
    'TablaDetalles.CantidadEntregada': 'Cantidad entregada',
    'TablaDetalles.Valor': 'Valor',
    'TablaDetalles.Serial': 'Serial',
    'TablaDetalles.Piezas': 'Piezas',
    // ModalEdicionDetalle
    'ModalEdicionDetalle.Titulo': 'Editar detalle',
    'ModalEdicionDetalle.CodigoBarrasItem': 'Codigo barras ítem',
    'ModalEdicionDetalle.DescripcionItem': 'Descripción ítem',
    'ModalEdicionDetalle.DivisionOrigen': 'División origen',
    'ModalEdicionDetalle.DivisionDestino': 'División destino',
    'ModalEdicionDetalle.CantidadRecibida': 'Cantidad recibida',
    'ModalEdicionDetalle.CantidadEntregada': 'Cantidad entregada',
    'ModalEdicionDetalle.Serial': 'Serial',
    'ModalEdicionDetalle.Lote': 'Lote',
    'ModalEdicionDetalle.FechaVencimiento': 'Fecha vencimiento',
};

export default mensajes;
