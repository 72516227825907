import { Card, CardHeader } from '@material-ui/core';
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Refresh as RefreshIcon,
    Remove as RemoveIcon,
} from '@material-ui/icons';
import moment from 'moment';
import React, { FunctionComponent, ReactNode } from 'react';

import Tabla, { Column } from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import ValorEnPesos from '../ValorEnPesos/ValorEnPesos';

interface ITablaDetallesProps {
    camposOcultar?: Array<keyof IDocumentoDetalleConsultaGeneral>;
    detalles: IDocumentoDetalleConsultaGeneral[];
    editarDetalle: (detalle: IDocumentoDetalleConsultaGeneral) => any;
    editarDetalleDeshabilitado?: boolean;
    eliminarDetalle: (detalle: IDocumentoDetalleConsultaGeneral) => any;
    eliminarDetalleDeshabilitado?: boolean;
    mostrarAcciones: boolean;
    recargarTabla: () => any;
    titulo: ReactNode;
}

const TablaDetalles: FunctionComponent<ITablaDetallesProps> = ({
    camposOcultar = [],
    detalles,
    editarDetalle,
    editarDetalleDeshabilitado = false,
    eliminarDetalle,
    eliminarDetalleDeshabilitado = false,
    mostrarAcciones,
    recargarTabla,
    titulo,
}) => {
    const obtenerColumnas = () => {
        const columnas: Array<Column<IDocumentoDetalleConsultaGeneral>> = [];
        if (!camposOcultar.includes('CodigoBarras'))
            columnas.push({
                field: 'CodigoBarras',
                title: <Texto id="TablaDetalles.CodigoBarras" />,
            });
        if (!camposOcultar.includes('DescripcionItem'))
            columnas.push({
                field: 'DescripcionItem',
                title: <Texto id="TablaDetalles.DescripcionItem" />,
            });
            if (!camposOcultar.includes('PesoFraccionSolicitado'))
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) => (detalle.ManejaPeso ? detalle.PesoFraccionSolicitado : <RemoveIcon />),
                title: <Texto id="TablaDetalles.PesoFraccionSolicitado" />,
            });
        if (!camposOcultar.includes('PesoFraccionRecibido'))
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) => (detalle.ManejaPeso ? detalle.PesoFraccionRecibido : <RemoveIcon />),
                title: <Texto id="TablaDetalles.PesoFraccionRecibido" />,
            });

           
        if (!camposOcultar.includes('Peso'))
            columnas.push({
                field: 'Peso',
                title: <Texto id="TablaDetalles.Peso" />,
            });
        if (!camposOcultar.includes('Piezas'))
            columnas.push({
                field: 'Piezas',
                title: <Texto id="TablaDetalles.Piezas" />,
            });
        if (!camposOcultar.includes('Lote'))
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) => (detalle.Lotes ? detalle.Lote : <RemoveIcon />),
                title: <Texto id="TablaDetalles.Lote" />,
            });
        if (!camposOcultar.includes('FechaVencimiento'))
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                    detalle.FechaVencimiento ? moment(detalle.FechaVencimiento).format('DD/MM/YYYY') : <RemoveIcon />,
                title: <Texto id="TablaDetalles.FechaVencimiento" />,
            });
        if (!camposOcultar.includes('FechaIngreso'))
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                    detalle.FechaIngreso ? moment(detalle.FechaIngreso).format('DD/MM/YYYY') : <RemoveIcon />,
                title: <Texto id="TablaDetalles.FechaIngreso" />,
            });
        if (!camposOcultar.includes('DescripcionDivisionOrigen'))
            columnas.push({
                field: 'DescripcionDivisionOrigen',
                title: <Texto id="TablaDetalles.DescripcionDivisionOrigen" />,
            });
        if (!camposOcultar.includes('DescripcionDivisionDestino'))
            columnas.push({
                field: 'DescripcionDivisionDestino',
                title: <Texto id="TablaDetalles.DescripcionDivisionDestino" />,
            });
        if (!camposOcultar.includes('CantidadSolicitada'))
            columnas.push({
                field: 'CantidadSolicitada',
                title: <Texto id="TablaDetalles.CantidadSolicitada" />,
            });
        if (!camposOcultar.includes('CantidadRecibida'))
            columnas.push({
                field: 'CantidadRecibida',
                title: <Texto id="TablaDetalles.CantidadRecibida" />,
            });
        if (!camposOcultar.includes('CantidadEntregada'))
            columnas.push({
                field: 'CantidadEntregada',
                title: <Texto id="TablaDetalles.CantidadEntregada" />,
            });
        if (!camposOcultar.includes('Valor'))
            columnas.push({
                render: (detalle) => (detalle.Valor ? <ValorEnPesos valor={detalle.Valor} /> : <RemoveIcon />),
                title: <Texto id="TablaDetalles.Valor" />,
            });
        if (!camposOcultar.includes('Serial'))
            columnas.push({
                field: 'Serial',
                title: <Texto id="TablaDetalles.Serial" />,
            });
        return columnas;
    };

    return (
        <Card variant="outlined">
            <CardHeader title={titulo} titleTypographyProps={{ variant: 'body1' }} />
            <Tabla
                actions={
                    mostrarAcciones
                        ? [
                            {
                                icon: () => <RefreshIcon color="action" />,
                                isFreeAction: true,
                                onClick: recargarTabla,
                            },
                            {
                                disabled: editarDetalleDeshabilitado,
                                icon: () => <EditIcon color={!editarDetalleDeshabilitado ? 'action' : 'disabled'} />,
                                onClick: (_, data) => editarDetalle(data),
                            },
                            {
                                disabled: eliminarDetalleDeshabilitado,
                                icon: () => (
                                    <DeleteIcon color={!eliminarDetalleDeshabilitado ? 'action' : 'disabled'} />
                                ),
                                onClick: async (_, data) => {
                                    await eliminarDetalle(data);
                                },
                            },
                        ]
                        : undefined
                }
                columns={obtenerColumnas()}
                data={detalles}
                options={{ padding: 'dense' }}
            />
        </Card>
    );
};

export default TablaDetalles;
