const mensajes = {
    // OrdenEntrada
    'OrdenEntrada.ValidacionNoTipoDocumentoId': 'You must select a document type',
    'OrdenEntrada.ValidacionNoProveedorId': 'You must select a provider',
    'OrdenEntrada.ValidacionNoBodegaDestino': 'You must select a destination warehouse',
    'OrdenEntrada.ValidacionCantidadSolicitadaCero': 'Requested quantity must be greater than 0',
    'OrdenEntrada.ValidacionValorCero': 'Value must be greater than 0',
    'OrdenEntrada.ValidacionNoResponsableId':'You must select a responsible',
    
    // ModalOrdenEntrada
    'ModalOrdenEntrada.TituloCreacion': 'Create Entry Order',
    'ModalOrdenEntrada.TituloEdicion': 'Edit Entry Order',
    'ModalOrdenEntrada.TituloVisualizacion': 'View Entry Order',
    'ModalOrdenEntrada.TituloTablaDetalles': 'Details',
    'ModalOrdenEntrada.TituloValorTotalDocumento': 'Document total',
    'permiso.ordenentrada': 'Access',
};

export default mensajes;
