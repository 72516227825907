import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import { IEntidadesFormularioDocumento } from 'Infotrack@Transversales/componentes/FormularioDocumento/FormularioDocumento';

// OrdenSalida

const CAMPOS_OCULTAR_DOCUMENTOS_FILTROS: Array<keyof IDocumentoFiltro> = [
    'ProveedorId',
    'BodegaDestino',
    'CodigoDocumentoPredecesor',
    'Proveedor','Serial'
];

const CAMPOS_OCULTAR_DOCUMENTOS_TABLA: Array<keyof IDocumentoConsultaGeneral> = [
    'DescipcionProveedorId',
    'DescripcionBodegaDestino',
    'PersonaRelacionada',
    'CodigoDocumentoPredecesor',
];

// ModalOrdenSalida

const ENTIDADES_DOCUMENTO_POR_DEFECTO: Partial<IEntidadesFormularioDocumento> = {
    bodegasOrigen: [],
    clientes: [],
    etapas: [],
    tiposDocumento: [],
};

const CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO: Array<keyof IDocumentoConsultaGeneral> = ['EtapaId', 'CodigoDocumento'];

const CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION: Array<keyof IDocumentoConsultaGeneral> = [
    ...CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
    'TipoDocumentoId',
    'CondicionComercial',
    'BodegaOrigen',
    'ResponsableId'
];

const CAMPOS_DESHABILITAR_DETALLES_FORMULARIO: Array<keyof IDocumentoDetalle> = ['ItemId','PesoFraccionRecibido','PesoFraccionSolicitado'];

const CAMPOS_OCULTAR_DOCUMENTO_FORMULARIO: Array<keyof IDocumentoConsultaGeneral> = [
    'ProveedorId',
    'BodegaDestino',
    'CodigoDocumentoPredecesor',
];

const CAMPOS_OCULTAR_DETALLES_FORMULARIO: Array<keyof IDocumentoDetalle> = [
    'DivisionDestino',
    'CantidadRecibida',
    'CantidadEntregada',
    'Serial',
    'Valor',
    'PesoFraccion',
    'PesoFraccionRecibido'
];

const CAMPOS_OCULTAR_DETALLES_TABLA: Array<keyof IDocumentoDetalleConsultaGeneral> = [
    'DivisionDestino',
    'CantidadRecibida',
    'CantidadEntregada',
    'Serial',
    'DescripcionDivisionDestino',
    'Peso',
    'Piezas',
    'PesoFraccion',
    'PesoFraccionRecibido'
   
];

const CAMPOS_EDITABLES_DETALLES_INVENTARIO: Array<keyof IDocumentoDetalleConsultaGeneral> = ['CantidadSolicitada'];
const CAMPOS_OCULTAR_DETALLES_INVENTARIO: Array<keyof IDocumentoDetalleConsultaGeneral> = [
    'DivisionDestino',
    'CantidadSolicitada',
    'CantidadRecibida',
    'CantidadEntregada',
    'PesoFraccionRecibido'
];

export {
    CAMPOS_DESHABILITAR_DETALLES_FORMULARIO,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
    CAMPOS_OCULTAR_DETALLES_FORMULARIO,
    CAMPOS_OCULTAR_DETALLES_TABLA,
    CAMPOS_OCULTAR_DOCUMENTO_FORMULARIO,
    CAMPOS_OCULTAR_DOCUMENTOS_FILTROS,
    CAMPOS_OCULTAR_DOCUMENTOS_TABLA,
    ENTIDADES_DOCUMENTO_POR_DEFECTO,
    CAMPOS_EDITABLES_DETALLES_INVENTARIO,
    CAMPOS_OCULTAR_DETALLES_INVENTARIO,
};
