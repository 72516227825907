import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';
import { ServiciosRest } from '@infotrack/utilitarios/servicios';

import IParametrosReporte from './entidades/IParametrosReporte';
import IReporteConsulta from './entidades/IReporteConsulta';

class ModeloReporte {
    private negocio: ServiciosRest;
    constructor(negocio: ServiciosRest) {
        this.negocio = negocio;
    }

    public async consultarParametrosReporte(nombreReporte: string, idAplicacion : string) {
        return manejadorRest<IRespuesta<IParametrosReporte>>(
            () =>
                this.negocio.get(
                    `ConfigReporte?idAplicacion=${idAplicacion}&catalogo=${nombreReporte}`
                ),
            true
        );
        //catalogo=StockBI_RPTGeneral
    }
    
   
}

export default ModeloReporte;
