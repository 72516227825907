const mensajes = {
    // OrdenSalida
    'OrdenSalida.ValidacionNoTipoDocumentoId': 'You must select a document type',
    'OrdenSalida.ValidacionNoBodegaOrigen': 'You must select an origin warehouse',
    'OrdenSalida.ValidacionNoClienteId': 'You must select a client',
    'OrdenSalida.ValidacionNoResponsableId':'You must select a responsible',
    // ModalOrdenSalida
    'ModalOrdenSalida.TituloCreacion': 'Create Departure Order',
    'ModalOrdenSalida.TituloEdicion': 'Edit Departure Order',
    'ModalOrdenSalida.TituloVisualizacion': 'View Departure Order',
    'ModalOrdenSalida.TituloTablaDetalles': 'Details',
    'ModalOrdenSalida.TituloValorTotalDocumento': 'Document total',
    'permiso.ordensalida': 'Access',
};

export default mensajes;
