const mensajes = {
    // OrdenEntrada
    'OrdenEntrada.ValidacionNoTipoDocumentoId': 'Debe seleccionar un tipo de documento',
    'OrdenEntrada.ValidacionNoProveedorId': 'Debe seleccionar un proveedor',
    'OrdenEntrada.ValidacionNoBodegaDestino': 'Debe seleccionar una bodega de destino',
    'OrdenEntrada.ValidacionCantidadSolicitadaCero': 'La cantidad solicitada debe ser mayor a 0',
    'OrdenEntrada.ValidacionValorCero': 'El valor debe ser mayor a 0',
    'OrdenEntrada.ValidacionNoResponsableId':'Debe seleccionar un responsable',
    // ModalOrdenEntrada
    'ModalOrdenEntrada.TituloCreacion': 'Crear Orden de Entrada',
    'ModalOrdenEntrada.TituloEdicion': 'Editar Orden de Entrada',
    'ModalOrdenEntrada.TituloVisualizacion': 'Ver Orden de Entrada',
    'ModalOrdenEntrada.TituloTablaDetalles': 'Detalles',
    'ModalOrdenEntrada.TituloValorTotalDocumento': 'Total documento',
    'permiso.ordenentrada': 'Acceso',
    // mensajes
    'MensajeOrdenEntrada.confirmarAnularDocumento': '¿Está seguro que desea anular el documento?',
};

export default mensajes;
