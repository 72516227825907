const mensajes = {
    'FormularioDocumento.Titulo': 'Documento',
    'FormularioDocumento.CambiarEtapa': 'Cambiar etapa',
    'FormularioDocumento.CampoCodigoDocumentoBase.Nombre': 'Código doc. base',
    'FormularioDocumento.CampoTipoDocumentoId.Nombre': 'Tipo documento',
    'FormularioDocumento.CampoEtapaId.Nombre': 'Etapa',
    'FormularioDocumento.CampoProveedorId.Nombre': 'Proveedor',
    'FormularioDocumento.CampoCodigoDocumento.Nombre': 'Código',
    'FormularioDocumento.CampoDireccionEntrega.Nombre': 'Dirección entrega',
    'FormularioDocumento.CampoFechaEntrega.Nombre': 'Fecha entrega',
    'FormularioDocumento.CampoCondicionComercial.Nombre': 'Condición comercial',
    'FormularioDocumento.CampoBodegaOrigen.Nombre': 'Bodega origen',
    'FormularioDocumento.CampoBodegaDestino.Nombre': 'Bodega destino',
    'FormularioDocumento.CampoDocumentoRelacionado.Nombre': 'Doc. relacionado',
    'FormularioDocumento.CampoObservacion.Nombre': 'Observación',
    'FormularioDocumento.CampoClienteId.Nombre': 'Cliente',
    "FormularioDocumento.DescargarDocumento": 'Visualizar PDF',
    "FormularioDocumento.SinRegistros": 'No se encontraron registros',
    "FormularioDocumento.ResponsableActual": 'Responsable actual',
    "FormularioDocumento.NuevoResponsable":"Nuevo Responsable",
    "FormularioDocumento.ResponsableInactivo":"El responsable asociado se encuentra inactivo",
    'FormularioDocumento.ConsultaSinResponsables':"No se encontraron usuarios con perfil gerente de marca consulte con el administrador."

};

export default mensajes;
