export default {
    'sidebar.consultainventario': 'Check inventory',
    'sidebar.historicoinventario': 'Historical inventory',
    'inventario.titulo': 'Inventory',
    'inventario.producto': 'Product',
    'inventario.ubicacion': 'Location',
    'inventario.fecha': 'Date',
    'inventario.codigoBarrasProducto': 'Barcode',
    'inventario.productoDescripcion': 'Description product',
    'inventario.productoSerial': 'Serial product',
    'inventario.productoLote': 'Lot product',
    'inventario.familiaIdProducto': 'Family product',
    'inventario.clasificacionIdProducto': 'Activity',
    'inventario.BodegaCodigo': 'Cellar code',
    'inventario.BodegaId': 'Cellar',
    'inventario.DivisionId': 'Division',
    'inventario.FechaInicialMovimiento': 'Initial date',
    'inventario.FechaFinalMovimiento': 'Final date',
    'inventario.tablaBodega': 'Store',
    'inventario.tablaDivision': 'Division',
    'inventario.tablaProducto': 'Product',
    'inventario.tablaPesoFraccion': 'Received weight',
    'inventario.tablaTipoMovimiento': 'Movement type',
    'inventario.tablaDocumento': 'Document',
    'inventario.tablaCantidad': 'Acount',
    'inventario.tablaCantidadTotal': 'Total amount',
    'inventario.tablaCantidadReserva': 'Reserved amount',
    'inventario.tablaSerial': 'Serial',
    'inventario.tablaLote': 'Lot',
    'inventario.tablaFechaVencimiento': 'Expiration Date',
    'inventario.tablaFechaMovimiento':'Movement Date',
    'inventario.tablaFechaIngreso': 'Admission date',
    'inventario.tablaValor': 'Value',
    'inventario.tablaEstado': 'Estate',
};
