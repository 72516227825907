import { Button, Grid, TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import { Edit as EditIcon } from '@material-ui/icons';

interface IDetallesFormularioProps {
    detallePesoPieza: IDetallePesoPieza;
    cambiarValorPesoPieza: (nombre: IDetallePesoPieza[keyof IDetallePesoPieza], valor: string) => void;
    guardarPesoPieza?: () => any;
    deshabilitado: boolean;
}

const DetallesFormulario: FunctionComponent<IDetallesFormularioProps> = ({
    detallePesoPieza,
    cambiarValorPesoPieza,
    guardarPesoPieza,
    deshabilitado,
}) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item lg={2} md={3} sm={6} xs={12}>
                    <TextField
                        disabled={deshabilitado}
                        fullWidth
                        label={<Texto id="DetallesFormulario.CampoPieza" />}
                        onChange={(event) => {
                            if (/^\d*$/.test(event.target.value)) {
                                cambiarValorPesoPieza('Piezas', event.target.value);
                            }
                        }}
                        required
                        value={detallePesoPieza.Piezas}
                        inputProps={{ maxLength: 6 }}
                    />
                </Grid>
                <Grid item lg={2} md={3} sm={6} xs={12}>
                    <TextField
                        disabled={deshabilitado}
                        fullWidth
                        label={<Texto id="DetallesFormulario.CampoPeso" />}
                        onChange={(event) => {
                            if (/^\d*$/.test(event.target.value)) {
                                cambiarValorPesoPieza('Peso', event.target.value);
                            }
                        }}
                        value={detallePesoPieza.Peso}
                        inputProps={{ maxLength: 6 }}
                        required
                    />
                </Grid>

                <Grid item lg={2} md={3} sm={6} xs={12}>
                    <Button
                        color="primary"
                        disabled={deshabilitado}
                        endIcon={<EditIcon />}
                        onClick={guardarPesoPieza}
                        size="small"
                        variant="contained"
                    >
                        <Texto id="boton.editar" />
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default DetallesFormulario;
