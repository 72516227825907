enum EstadosFormularioDocumento {
    CREACION = 'CREACION',
    EDICION = 'EDICION',
    VISUALIZACION = 'VISUALIZACION',
}
enum EnumEstadoUsuario {
    SinUsuario = 0,
    Activo = 1,
    Inactivo = 2,
    Borrado = 3,
    Verificacion = 4,
    Bloqueado = 5,
    Vencido = 6,
}

export { EstadosFormularioDocumento, EnumEstadoUsuario };
