const mensajes = {
    // SalidaInventario
    'SalidaInventario.ValidacionNoTipoDocumentoId': 'Debe seleccionar un tipo de documento',
    'SalidaInventario.ValidacionNoBodegaOrigen': 'Debe seleccionar una bodega de origen',
    'SalidaInventario.ValidacionNoClienteId': 'Debe seleccionar un cliente',
    'SalidaInventario.ValidacionNoResponsableId':'Debe seleccionar un responsable',
    // ModalSalidaInventario
    'ModalSalidaInventario.TituloCreacion': 'Crear Salida Inventario',
    'ModalSalidaInventario.TituloEdicion': 'Editar Salida Inventario',
    'ModalSalidaInventario.TituloVisualizacion': 'Ver Salida Inventario',
    'ModalSalidaInventario.TituloTablaDetalles': 'Detalles',
    'ModalSalidaInventario.TituloTablaDetallesDocumentoBase': 'Detalles Documento Base',
    'permiso.salidainventario': 'Acceso',
    // mensajes
    'MensajeSalidaInventario.confirmarAnularDocumento': '¿Está seguro que desea anular el documento?',
};

export default mensajes;
