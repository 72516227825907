const mensajes = {
    // DetallesFormulario
    'DetallesFormulario.TituloCreacion': 'Agregar detalles',
    'DetallesFormulario.TituloEdicion': 'Editar detalle',
    'DetallesFormulario.TooltipAsignarSeriales': 'Asignar seriales',
    'DetallesFormulario.ValidacionNoItem': 'Debe seleccionar un ítem',
    'DetallesFormulario.ValidacionNoCantidad': 'La cantidad solicitada debe ser mayor a 0',
    'DetallesFormulario.ValidacionNoDivisionOrigen': 'Debe seleccionar una división de origen',
    'DetallesFormulario.ValidacionNoDivisionDestino': 'Debe seleccionar una división de destino',
    'DetallesFormulario.ValidacionNoLote': 'Este ítem requiere lote',
    'DetallesFormulario.ValidacionNoPeso': 'Este ítem requiere Peso',
    'DetallesFormulario.ValidacionNoFechaVencimiento': 'Este ítem requiere fecha de vencimiento.',
    'DetallesFormulario.ValidacionSerialesIncompletos': 'No ha ingresado algun serial, por favor verifique',
    // CampoCodigoBarrasItem
    'DetallesFormulario.CampoCodigoBarrasItem.Nombre': 'Código ítem',
    // CampoDescripcionItem
    'DetallesFormulario.CampoDescripcionItem.Nombre': 'Desc. ítem',
    // CampoDivisionOrigen
    'DetallesFormulario.CampoDivisionOrigen.Nombre': 'División origen',
    // CampoDivisionDestino
    'DetallesFormulario.CampoDivisionDestino.Nombre': 'División destino',
    // CampoCantidadSolicitada
    'DetallesFormulario.CampoCantidadSolicitada.Nombre': 'Cantidad solicitada',
    // CampoCantidadRecibida
    'DetallesFormulario.CampoCantidadRecibida.Nombre': 'Cantidad recibida',
    // CampoCantidadEntregada
    'DetallesFormulario.CampoCantidadEntregada.Nombre': 'Cantidad entregada',
    // CampoValor
    'DetallesFormulario.CampoValor.Nombre': 'Valor',
    // CampoLote
    'DetallesFormulario.CampoLote.Nombre': 'Lote',
    // CampoPeso
    'DetallesFormulario.CampoPeso.Nombre': 'Peso',
    // CampoFechaVencimiento
    'DetallesFormulario.CampoFechaVencimiento.Nombre': 'Fecha vencimiento',
    // CampoSerial
    'DetallesFormulario.CampoSerial.Nombre': 'Serial',
    'DetallesFormulario.ValidacionPeso':'El peso no puede ser mayor al peso solicitado.',
    'DetallesFormulario.ValidacionCantidadNoValidaPesoRecibido':'La cantidad  recibida es mayor a la cantidad solicitada.',
    'DetallesFormulario.ValidacionCantidadNoValidaPesoSolicitado':'La cantidad  entregada es mayor a la cantidad solicitada.',
    'DetallesFormulario.TituloPesoPieza':'Piezas y peso',
    'DetallesFormulario.CampoPeso':'Peso',
    'DetallesFormulario.CampoPieza':'Piezas'
};

export default mensajes;
