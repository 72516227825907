import { IRespuesta, IRespuestaPaginada } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocio ,middleware} from 'Infotrack@Modelos/conexiones';
import IDocumentoFiltroPaginador from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltroPaginador';
import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import IDocumentoBaseConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoBaseConsulta';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';

class ModeloTraslados {
    private NOMBRE_CONTROLADOR = 'Traslado';

    public async editarDetalle(detalle: IDocumentoDetalle) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.put(`${this.NOMBRE_CONTROLADOR}/EditarDetalle`, detalle),
            true
        );
    }

    public async eliminarDetalle(detalle: IDocumentoDetalle) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.delete(`${this.NOMBRE_CONTROLADOR}/EliminarDetalle`, detalle),
            true
        );
    }

    public async guardarDetalle(detalles: IDocumentoDetalle[]) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/GuardarDetalle`, detalles),
            true
        );
    }

    public async consultarDocumentoBaseTraslados(documentoBase: IDocumentoBaseConsulta) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/ConsultarDocumentoBase`,documentoBase),
            true
        );
    }

    public async guardarEncabezado(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/GuardarEncabezado`, documento),
            true
        );
    }

    public async editarEncabezado(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/EditarEncabezado`, documento),
            true
        );
    }

    public async anularDocumento(documentoId: string) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/AnularDocumento/${documentoId}`, {}),
            true
        );
    }

    public async consultarListaDocumentos(documentoFiltroPaginador: Partial<IDocumentoFiltroPaginador>) {
        return manejadorRest<IRespuestaPaginada<IDocumentoConsultaGeneral>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/ConsultarListaDocumentos`, documentoFiltroPaginador),
            true
        );
    }

    public async consultarDetallesDocumento(filtro: Partial<IDocumentoDetalle>) {
        return manejadorRest<IRespuesta<IDocumentoDetalleConsultaGeneral>>(
            () => middleware.get(`DocumentoDetalle?idDocumento=${filtro.DocumentoId}`),
            true
        );
    }

    public async consultarEncabezadoDocumento(filtro: Partial<IDocumento>) {
        return manejadorRest<IRespuesta<IDocumentoConsultaGeneral>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/ConsultarEncabezadoDocumento`, filtro),
            true
        );
    }

    public async consultarEtapas(documentoId: string) {
        return manejadorRest<IRespuesta<IEtapas>>(
            () => negocio.get(`${this.NOMBRE_CONTROLADOR}/ConsultarEtapas/${documentoId}`),
            true
        );
    }

    public async cambiarEtapa(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.put(`${this.NOMBRE_CONTROLADOR}/CambiarEtapa`, documento),
            true
        );
    }

    public async generarArchivoPDF(documentoId: string, TipoDocumentoId: number) {
        return negocio.get(`${this.NOMBRE_CONTROLADOR}/GenerarReporte/${documentoId}/${TipoDocumentoId}`, undefined, {
            responseType: 'arraybuffer',
        });
    }

    public async guardarPiezasPeso(detallePesoPieza: IDetallePesoPieza, idAplicacion: string, idUsuario: string) {

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                aplicacionId: idAplicacion,
                usuarioId: idUsuario,
            },
            body: JSON.stringify(detallePesoPieza),
        };
        let respuestaPeticion = await fetch(`${process.env.REACT_APP_URL_GUARDAR_DETALLE_PESO_PIEZAS!}PaqueteProducto`, requestOptions);
        const data = await respuestaPeticion.json();
        console.log(respuestaPeticion);
        return respuestaPeticion.status === 200;
    }
}
export default ModeloTraslados;
