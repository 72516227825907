import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';

import IDocumentoFiltro from '../../../../modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import IDocumento from '../../../../modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IDocumentoDetalle from '../../../../modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import ModeloTraslados from '../../../../modelos/negocioRefactor/modelos/Traslados/ModeloTraslados';
import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';
import IDocumentoBaseConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoBaseConsulta';

class TrasladosCM {
    private modeloTraslados: ModeloTraslados;

    constructor() {
        this.modeloTraslados = new ModeloTraslados();
    }

    public async editarDetalle(detalle: IDocumentoDetalle) {
        if (!this.validarDetalle(detalle)) return false;
        const respuesta = await this.modeloTraslados.editarDetalle(detalle);
        return respuesta.data.Resultado;
    }

    // public async consultarDocumentoBaseSalidaInventario(codigoDocumentoBase: string) {
    //     const respuesta = await this.modeloTraslados.consultarDocumentoBaseSalidaInventario(codigoDocumentoBase);
    //     return respuesta.data.Resultado;
    // }

    public async eliminarDetalle(detalle: IDocumentoDetalle) {
        const respuesta = await this.modeloTraslados.eliminarDetalle(detalle);
        return respuesta.data.Resultado;
    }

    public async guardarDetalle(detalles: IDocumentoDetalle[]) {
        if (detalles.some((detalle) => !this.validarDetalle(detalle))) return false;
        const respuesta = await this.modeloTraslados.guardarDetalle(detalles);
        return respuesta.data.Resultado;
    }

    public async guardarEncabezado(documento: IDocumento) {
        const respuesta = await this.modeloTraslados.guardarEncabezado(documento);
        return respuesta.data;
    }

    public async editarEncabezado(documento: IDocumento) {
        const respuesta = await this.modeloTraslados.editarEncabezado(documento);
        return respuesta.data;
    }

    public async anularDocumento(documentoId: string) {
        const respuesta = await this.modeloTraslados.anularDocumento(documentoId);
        return respuesta.data.Resultado;
    }

    public consultarListaDocumentos = async (paginador: IPaginador, filtro: IDocumentoFiltro) => {
        filtro.ConsultarBodegaDestino = true;
        filtro.idTipoProceso = TiposProceso.TRASLADO;
        const respuesta = await this.modeloTraslados.consultarListaDocumentos({
            Filtro: filtro,
            Paginador: paginador,
        });
        return respuesta.data;
    };

    public async consultarDetallesDocumento(filtro: Partial<IDocumentoDetalle>) {
        const respuesta = await this.modeloTraslados.consultarDetallesDocumento(filtro);
        return respuesta.data.Entidades;
    }

    public async consultarEncabezadoDocumento(filtro: Partial<IDocumento>) {
        const respuesta = await this.modeloTraslados.consultarEncabezadoDocumento(filtro);
        return respuesta.data.Entidades[0];
    }

    public async consultarEtapas(documentoId: string) {
        const respuesta = await this.modeloTraslados.consultarEtapas(documentoId);
        return respuesta.data.Entidades;
    }

    public async cambiarEtapa(documento: IDocumento) {
        const respuesta = await this.modeloTraslados.cambiarEtapa(documento);
        return respuesta.data.Resultado;
    }

    public async consultarDocumentoBase(documentoBase: IDocumentoBaseConsulta) {
        const respuesta = await this.modeloTraslados.consultarDocumentoBaseTraslados(documentoBase);
        return respuesta.data.Entidades;
    }

    public async generarArchivoPDF(documentoId: string, TipoDocumentoId: number) {
        const respuesta = await this.modeloTraslados.generarArchivoPDF(documentoId, TipoDocumentoId);
        return respuesta.data as string;
    }

    public validarEntidad(documento: IDocumento): boolean {
        if (
            documento.BodegaDestino === undefined ||
            documento.BodegaOrigen === undefined ||
            documento.TipoDocumentoId === 0
        ) {
            notificacionGlobal('Por favor ingrese todos los campos requeridos (*)', 6000, 'warning', true);
            return false;
        }
        return true;
    }
    private validarDetalle(detalle: IDocumentoDetalle) {
        if (!detalle.DivisionOrigen) {
            notificacionGlobal('ModalRecepcion.ValidacionNoDivisionOrigen', 3000, 'warning', true);
            return false;
        }
        if (!detalle.DivisionDestino) {
            notificacionGlobal('ModalRecepcion.ValidacionNoDivisionDestino', 3000, 'warning', true);
            return false;
        }
        if (detalle.CantidadRecibida! < detalle.CantidadEntregada!) {
            notificacionGlobal('TablaDetallesPlantilla.ValidacionCantidadMayorASolicitada', 3000, 'warning', true);
            return false;
        }
        return true;
    }

    public async guardarPesoPieza(detalle: IDetallePesoPieza, idAplicacion: string, idUsuario: string) {
       
     let respuesta =   await this.modeloTraslados.guardarPiezasPeso(detalle, idAplicacion, idUsuario);

     if(respuesta){
        notificacionGlobal('Edición exitosa', 3000, 'success', true);
     }else{
        notificacionGlobal('Fallo edición contacte con el administrador del sistema', 3000, 'warning', true);
     }
    }

    public validarPesoPieza(detalle: IDetallePesoPieza) {
        if (detalle.Peso === '' || detalle.Piezas === '') {
            notificacionGlobal('Por favor ingrese todos los campos requeridos (*)', 6000, 'warning', true);
            return false;
        }
        return true;
    }
}
export default TrasladosCM;
