import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import { IEntidadesFormularioDocumento } from 'Infotrack@Transversales/componentes/FormularioDocumento/FormularioDocumento';

// EntradaInventario

const CAMPOS_OCULTAR_DOCUMENTOS_FILTROS: Array<keyof IDocumentoFiltro> = [
    'BodegaOrigen',
    'ClienteId',
    'Cliente',
    'Serial',
];

const CAMPOS_OCULTAR_DOCUMENTOS_TABLA: Array<keyof IDocumentoConsultaGeneral> = [
    'DescripcionBodegaOrigen',
    'DescripcionClienteId',
];

// ModalEntradaInventario

const ENTIDADES_DOCUMENTO_POR_DEFECTO: Partial<IEntidadesFormularioDocumento> = {
    bodegasDestino: [],
    etapas: [],
    proveedores: [],
    tiposDocumento: [],
};

const CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO: Array<keyof IDocumentoConsultaGeneral> = [
    'EtapaId',
    'CodigoDocumento',
    'CodigoDocumentoPredecesor',
];

const CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_DOCUMENTO_BASE: Array<keyof IDocumentoConsultaGeneral> = [
    ...CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
    'BodegaOrigen',
    'DescipcionProveedorId',
    'ClienteId',
    'DescripcionClienteId',
];

const CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION: Array<keyof IDocumentoConsultaGeneral> = [
    ...CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
    'TipoDocumentoId',
    'BodegaOrigen',
    'BodegaDestino',
    'ProveedorId',
];

const CAMPOS_OCULTAR_DOCUMENTO_FORMULARIO: Array<keyof IDocumentoConsultaGeneral> = ['BodegaOrigen', 'ClienteId'];

const CAMPOS_OCULTAR_DETALLES_PLANTILLA: Array<keyof IDocumentoDetalleConsultaGeneral> = [
    'DescripcionDivisionOrigen',
    'CantidadEntregada',
    'DivisionOrigen',
];

const CAMPOS_EDITABLES_DETALLES_PLANTILLA: Array<keyof IDocumentoDetalleConsultaGeneral> = [
    'CantidadSolicitada',
    'Lote',
    'FechaVencimiento',
];

const CAMPOS_OCULTAR_DETALLES_FORMULARIO: Array<keyof IDocumentoDetalle> = [
    'DivisionOrigen',
    'CantidadSolicitada',
    'CantidadEntregada',
    'Valor',
    'PesoFraccionSolicitado',
];

const CAMPOS_OCULTAR_DETALLES_TABLA: Array<keyof IDocumentoDetalleConsultaGeneral> = [
    'DescripcionDivisionOrigen',
    'CantidadSolicitada',
    'CantidadEntregada',
    'DivisionOrigen',
    'PesoFraccionSolicitado',
    'Peso',
    'Piezas'
];

export {
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_DOCUMENTO_BASE,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
    CAMPOS_EDITABLES_DETALLES_PLANTILLA,
    CAMPOS_OCULTAR_DETALLES_FORMULARIO,
    CAMPOS_OCULTAR_DETALLES_PLANTILLA,
    CAMPOS_OCULTAR_DETALLES_TABLA,
    CAMPOS_OCULTAR_DOCUMENTO_FORMULARIO,
    CAMPOS_OCULTAR_DOCUMENTOS_FILTROS,
    CAMPOS_OCULTAR_DOCUMENTOS_TABLA,
    ENTIDADES_DOCUMENTO_POR_DEFECTO,
};
