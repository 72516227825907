const mensajes = {
    // TablaDocumentos
    'TablaDocumentos.Acciones': 'Actions',
    'TablaDocumentos.VerDocumento': 'View document',
    'TablaDocumentos.EditarDocumento': 'Edit document',
    'TablaDocumentos.AnularDocumento': 'Void document',
    'TablaDocumentos.DescripcionTipoDocumentoId': 'Document type',
    'TablaDocumentos.DescripcionEtapaId': 'Stage',
    'TablaDocumentos.DescripcionProveedorId': 'Supplier',
    'TablaDocumentos.CodigoDocumento': 'Code',
    'TablaDocumentos.DireccionEntrega': 'Delivery address',
    'TablaDocumentos.FechaEntrega': 'Delivery date',
    'TablaDocumentos.CondicionComercial': 'Commercial condition',
    'TablaDocumentos.DescripcionBodegaOrigen': 'Origin warehouse',
    'TablaDocumentos.DescripcionBodegaDestino': 'Destination warehouse',
    'TablaDocumentos.DocumentoRelacionado': 'Related document',
    'TablaDocumentos.Observacion': 'Observation',
    'TablaDocumentos.PersonaRelacionada': 'Related person',
    'TablaDocumentos.CodigoDocumentoPredecesor': 'Base document code',
    'TablaDocumentos.FechaCreacion': 'Creation date',
    'TablaDocumentos.DescripcionClienteId': 'Client',
    'TablaDocumentos.DescripcionEstado': 'State',
    'TablaDocumentos.OrigenExterno': 'External origin',
};

export default mensajes;
