import React from 'react';
import { IComponentesAplicacion, IModulo } from '@infotrack/presentacion-transversales/interfacesComunes';

import Banco from 'Infotrack@Modulos/administracion/banco';
import GrupoActividadEconomica from 'Infotrack@Modulos/administracion/grupoActividadEconomica';
import Modulo from 'Infotrack@Modulos/administracion/modulo';
import Pais from 'Infotrack@Modulos/administracion/pais';
import PermisosPerfil from 'Infotrack@Modulos/administracion/permisosPerfil';
import Perfil from 'Infotrack@Modulos/administracion/perfil';
import TipoContribuyente from 'Infotrack@Modulos/administracion/tipoContribuyente';
import TipoCuentaBancaria from 'Infotrack@Modulos/administracion/tipoCuentaBancaria';
import TipoIdentificacion from 'Infotrack@Modulos/administracion/tipoIdentificacion';
import TipoRegimen from 'Infotrack@Modulos/administracion/tipoRegimen';
import TipoSociedad from 'Infotrack@Modulos/administracion/tipoSociedad';
import Usuario from 'Infotrack@Modulos/administracion/usuario';
import GestionBodegas from 'Infotrack@Modulos/bodegas/gestionBodegas';
import ConsultaInventario from 'Infotrack@Modulos/inventario/consultaInventario';
import DevolucionProveedor from 'Infotrack@Modulos/inventario/devolucionProveedor';
import GestionAuditorias from 'Infotrack@Modulos/inventario/gestionAuditorias';
import GestionMovimientos from 'Infotrack@Modulos/inventario/gestionMovimientos';
import GestionOperador from 'Infotrack@Modulos/inventario/gestionOperador';
import GestionProducto from 'Infotrack@Modulos/inventario/gestionProducto';
import HistoricoInventario from 'Infotrack@Modulos/inventario/historicoInventario';
import MonitorInventario from 'Infotrack@Modulos/inventario/monitorInventario';
import ReversionMovimientos from 'Infotrack@Modulos/inventario/reversionMovimientos';
import BodegasTiposPropiedades from 'Infotrack@Modulos/parametrizacion/bodegasTiposPropiedades';
import ClasesTecnicas from 'Infotrack@Modulos/parametrizacion/clasesTecnicas';
import Clasificaciones from 'Infotrack@Modulos/parametrizacion/clasificaciones';
import Clientes from 'Infotrack@Modulos/parametrizacion/clientes';
import Columnas from 'Infotrack@Modulos/parametrizacion/columnas';
import DivisionesTiposPropiedades from 'Infotrack@Modulos/parametrizacion/divisionesTiposPropiedades';
import EstadosDetalles from 'Infotrack@Modulos/parametrizacion/estadosDetalles';
import Etapas from 'Infotrack@Modulos/parametrizacion/etapas';
import Fabricantes from 'Infotrack@Modulos/parametrizacion/fabricantes';
import Familias from 'Infotrack@Modulos/parametrizacion/familias';
import Filas from 'Infotrack@Modulos/parametrizacion/filas';
import Flujos from 'Infotrack@Modulos/parametrizacion/flujos';
import FlujosEtapas from 'Infotrack@Modulos/parametrizacion/flujosEtapas';
import Marcas from 'Infotrack@Modulos/parametrizacion/marcas';
import Pisos from 'Infotrack@Modulos/parametrizacion/pisos';
import Productos from 'Infotrack@Modulos/parametrizacion/productos';
import Proveedores from 'Infotrack@Modulos/parametrizacion/proveedores';
import Referencias from 'Infotrack@Modulos/parametrizacion/referencias';
import ResolucionesFacturaciones from 'Infotrack@Modulos/parametrizacion/resolucionesFacturaciones';
import TiposBodegas from 'Infotrack@Modulos/parametrizacion/tiposBodegas';
import TiposClientes from 'Infotrack@Modulos/parametrizacion/tiposClientes';
import TiposDivisiones from 'Infotrack@Modulos/parametrizacion/tiposDivisiones';
import TiposDocumento from 'Infotrack@Modulos/parametrizacion/TiposDocumento/TiposDocumento';
import TiposMovimientos from 'Infotrack@Modulos/parametrizacion/tiposMovimientos';
import TiposUnidades from 'Infotrack@Modulos/parametrizacion/tiposUnidades';
import Unidades from 'Infotrack@Modulos/parametrizacion/unidades';
import Devoluciones from 'Infotrack@Modulos/pedidos/devoluciones';
import MonitorPedidos from 'Infotrack@Modulos/pedidos/monitorPedidos';
import Almacenamiento from 'Infotrack@Modulos/procesos/almacenamiento';
import Auditorias from 'Infotrack@Modulos/procesos/auditorias';
import Despachos from 'Infotrack@Modulos/procesos/despachos';
import Auditoria from 'Infotrack@Modulos/smartStock/Auditoria/Auditoria';
import Conteo from 'Infotrack@Modulos/smartStock/Conteo/ModalConteo/ModalConteo';
import ComparacionConteo from 'Infotrack@Modulos/smartStock/Conteo/ComparacionConteo/ComparacionConteo';
import EntradaDevolucion from 'Infotrack@Modulos/smartStock/EntradaDevolucion/EntradaDevolucion';
import EntradaInventario from 'Infotrack@Modulos/smartStock/EntradaInventario/EntradaInventario';
import OrdenEntrada from 'Infotrack@Modulos/smartStock/OrdenEntrada/OrdenEntrada';
import OrdenSalida from 'Infotrack@Modulos/smartStock/OrdenSalida/OrdenSalida';
import SalidaDevolucion from 'Infotrack@Modulos/smartStock/SalidaDevolucion/SalidaDevolucion';
import SalidaInventario from 'Infotrack@Modulos/smartStock/SalidaInventario/SalidaInventario';
import Traslados from 'Infotrack@Modulos/smartStock/traslados/Traslados';
import Empresa from 'Infotrack@Modulos/administracion/empresaold';
import CambioContrasenia from 'Infotrack@Modulos/administracion/cambioContrasenia';
import Agencia from 'Infotrack@Modulos/administracion/agencia';
import DatosPersonales from 'Infotrack@Modulos/datosPersonalesModulo';
import { ILlavesTemas } from 'Infotrack@Transversales/constantes/temas';
import Cargo from 'Infotrack@Modulos/administracion/moduloCargo';
import OrdenTraslado from 'Infotrack@Modulos/smartStock/OrdenTraslado/OrdenTraslado';

import SelectMasivoItems from '../componentes/selectMasivoItems';
import FlujoEtapas from '../../modulos/parametrizacion/flujoEtapas/FlujosEtapas';
import PlantillaImpresion from 'Infotrack@Modulos/parametrizacion/PlantillaImpresion/PlantillaImpresion';
import Reporte from '../componentes/reportePowerBI';
import TablaImpresion from 'Infotrack@Modulos/impresion/tablaImpresion';

const rutasApp: Array<IModulo<React.ReactNode>> = [
    {
        rutaModulo: 'Administracion',
        rutaSubModuloPorDefecto: 'Banco',
        subModulos: [
            { rutaSubModulo: 'cambioContrasenia', componente: CambioContrasenia },
            { rutaSubModulo: 'Banco', componente: Banco },
            { rutaSubModulo: 'GrupoActividadEconomica', componente: GrupoActividadEconomica },
            { rutaSubModulo: 'Modulo', componente: Modulo },
            { rutaSubModulo: 'Pais', componente: Pais },
            { rutaSubModulo: 'Perfil', componente: Perfil },
            { rutaSubModulo: 'PermisosPerfil', componente: PermisosPerfil },
            { rutaSubModulo: 'TipoContribuyente', componente: TipoContribuyente },
            { rutaSubModulo: 'TipoCuentaBancaria', componente: TipoCuentaBancaria },
            { rutaSubModulo: 'TipoIdentificacion', componente: TipoIdentificacion },
            { rutaSubModulo: 'TipoRegimen', componente: TipoRegimen },
            { rutaSubModulo: 'TipoSociedad', componente: TipoSociedad },
            { rutaSubModulo: 'UsuariosPersona', componente: Usuario },
            { rutaSubModulo: 'Empresa', componente: Empresa },
            { rutaSubModulo: 'Agencias', componente: Agencia },
        ],
    },
    {
        rutaModulo: 'Parametrizacion',
        rutaSubModuloPorDefecto: 'Clasificaciones',
        subModulos: [
            { rutaSubModulo: 'Clientes', componente: Clientes },
            { rutaSubModulo: 'EstadosDetalles', componente: EstadosDetalles },
            { rutaSubModulo: 'Etapas', componente: Etapas },
            { rutaSubModulo: 'Flujos', componente: Flujos },
            { rutaSubModulo: 'FlujosEtapas', componente: FlujosEtapas },
            { rutaSubModulo: 'Proveedores', componente: Proveedores },
            { rutaSubModulo: 'ResolucionesFacturaciones', componente: ResolucionesFacturaciones },
            { rutaSubModulo: 'TiposClientes', componente: TiposClientes },
            { rutaSubModulo: 'TiposDocumento', componente: TiposDocumento },
            { rutaSubModulo: 'PlantillaImpresion', componente: PlantillaImpresion },
            { rutaSubModulo: 'TiposMovimientos', componente: TiposMovimientos },
            { rutaSubModulo: 'FlujoEtapas', componente: FlujoEtapas },
            { rutaSubModulo: 'Cargo', componente: Cargo },
        ],
    },
    {
        rutaModulo: 'Bodegas',
        rutaSubModuloPorDefecto: 'GestionBodegas',
        subModulos: [
            { rutaSubModulo: 'Filas', componente: Filas },
            { rutaSubModulo: 'Pisos', componente: Pisos },
            { rutaSubModulo: 'Columnas', componente: Columnas },
            { rutaSubModulo: 'TiposDivisiones', componente: TiposDivisiones },
            { rutaSubModulo: 'TiposBodegas', componente: TiposBodegas },
            { rutaSubModulo: 'DivisionesTiposPropiedades', componente: DivisionesTiposPropiedades },
            { rutaSubModulo: 'BodegasTiposPropiedades', componente: BodegasTiposPropiedades },
            { rutaSubModulo: 'GestionBodegas', componente: GestionBodegas },
            { rutaSubModulo: 'SelectMasivoItems', componente: SelectMasivoItems },
        ],
    },
    {
        rutaModulo: 'Productos',
        rutaSubModuloPorDefecto: 'Familias',
        subModulos: [
            { rutaSubModulo: 'Familias', componente: Familias },
            { rutaSubModulo: 'Actividad', componente: Clasificaciones },
            { rutaSubModulo: 'ClasesTecnicas', componente: ClasesTecnicas },
            { rutaSubModulo: 'Referencias', componente: Referencias },
            { rutaSubModulo: 'Marcas', componente: Marcas },
            { rutaSubModulo: 'Fabricantes', componente: Fabricantes },
            { rutaSubModulo: 'TiposUnidades', componente: TiposUnidades },
            { rutaSubModulo: 'Unidades', componente: Unidades },
            { rutaSubModulo: 'GestionProducto', componente: GestionProducto },
            { rutaSubModulo: 'Impresion', componente: TablaImpresion },
        ],
    },
    {
        //TODO: revisar parametro noValidarPermisos
        rutaModulo: 'Inventario',
        rutaSubModuloPorDefecto: 'ConsultaInventario',
        subModulos: [
            { rutaSubModulo: 'ConsultaInventario', componente: ConsultaInventario },
            { rutaSubModulo: 'DevolucionProveedor', componente: DevolucionProveedor },
            { rutaSubModulo: 'GestionAuditorias', componente: GestionAuditorias },
            { rutaSubModulo: 'AuditoriasInventario', componente: Auditorias },
            { rutaSubModulo: 'Auditoria', componente: Auditoria },
            { rutaSubModulo: 'GestionMovimientos', componente: GestionMovimientos },
            { rutaSubModulo: 'GestionOperador', componente: GestionOperador },
            { rutaSubModulo: 'HistoricoInventario', componente: HistoricoInventario },
            { rutaSubModulo: 'MonitorInventario', componente: MonitorInventario },
            { rutaSubModulo: 'ReversionMovimientos', componente: ReversionMovimientos },
        ],
    },
    {
        rutaModulo: 'Inventario',
        noValidarPermisos: true,
        rutaSubModuloPorDefecto: 'Conteo/ComparacionConteo',
        subModulos: [
            { rutaSubModulo: 'Conteo/:auditoriaId/:auditoriaConteoId', componente: Conteo },
            { rutaSubModulo: 'Conteo/ComparacionConteo', componente: ComparacionConteo },
        ],
    },
    {
        rutaModulo: 'Procesos',
        rutaSubModuloPorDefecto: 'Recepcion',
        subModulos: [
            { rutaSubModulo: 'EntradaInventario', componente: EntradaInventario },
            { rutaSubModulo: 'SalidaInventario', componente: SalidaInventario },
            { rutaSubModulo: 'EntradaDevolucion', componente: EntradaDevolucion },
            { rutaSubModulo: 'SalidaDevolucion', componente: SalidaDevolucion },
            { rutaSubModulo: 'Traslados', componente: Traslados },
            { rutaSubModulo: 'Despachos', componente: Despachos },
            { rutaSubModulo: 'TrasladosBodegas', componente: Traslados },
            { rutaSubModulo: 'TrasladosDivisiones', componente: Almacenamiento },
        ],
    },
    {
        noValidarPermisos: true,
        rutaModulo: 'Ordenes',
        rutaSubModuloPorDefecto: 'OrdenEntrada',
        subModulos: [
            { componente: OrdenEntrada, rutaSubModulo: 'OrdenEntrada' },
            { componente: OrdenSalida, rutaSubModulo: 'OrdenSalida' },
            { componente: OrdenTraslado, rutaSubModulo: 'OrdenTraslado' },
        ],
    },
    {
        rutaModulo: 'Pedidos',
        rutaSubModuloPorDefecto: 'GestionPedidos',
        subModulos: [
            { rutaSubModulo: 'Devoluciones', componente: Devoluciones },
            { rutaSubModulo: 'MonitorPedidos', componente: MonitorPedidos },
        ],
    },
    {
        noValidarPermisos: true,
        rutaModulo: 'Usuario',
        rutaSubModuloPorDefecto: 'DatosPersonales',
        subModulos: [
            { rutaSubModulo: 'DatosPersonales', componente: DatosPersonales },
            { rutaSubModulo: 'cambioContrasenia', componente: CambioContrasenia },
            { rutaSubModulo: 'MonitorPedidos', componente: MonitorPedidos },
        ],
    },
    {
        rutaModulo: 'Reportes',
        rutaSubModuloPorDefecto: 'Reportes',
        subModulos: [{ componente: () => <Reporte nombreReporte={'StockBI_RPTGeneral'} filtro={''} />, rutaSubModulo: 'ReporteGeneral' },
        { componente: () => <Reporte nombreReporte={'StockBI_POPRemesa'} filtro={'BodegasUsuarios/IdUsuario'} />, rutaSubModulo: 'ReporteBavaria' },
        { componente: () => <Reporte nombreReporte={'StockBI_POP_InvMarcas'} filtro={'BodegasUsuarios/IdUsuario'} />, rutaSubModulo: 'ReporteMarcas' },
        { componente: () => <Reporte nombreReporte={'StockBI_POP_InvRegMarcas'} filtro={'BodegasUsuarios/IdUsuario'} />, rutaSubModulo: 'ReporteInvMarcas' },
        {
            componente: () => (
                <Reporte nombreReporte={'RPT_InventarioCoimpresores'} filtro={'BodegasUsuarios/IdUsuario'} />
            ),
            rutaSubModulo: 'InventarioCoimpresores',
        },
        {
            componente: () => <Reporte nombreReporte={'RPT_Movimiento'} filtro={'BodegasUsuarios/IdUsuario'} />,
            rutaSubModulo: 'Movimiento',
        },
        {
            componente: () => <Reporte nombreReporte={'RPT_Movimiento'} filtro={'BodegasUsuarios/IdUsuario'} />,
            rutaSubModulo: 'MovimientoInventario',
        },
        {
            componente: () => <Reporte nombreReporte={'RPT_Bodegas'} filtro={'BodegasUsuarios/IdUsuario'} />,
            rutaSubModulo: 'InventarioBodegas',
        }]
    }
];

export const componentesAplicacion: Array<IComponentesAplicacion<keyof ILlavesTemas, React.ReactNode>> = [
    { componentePrincipal: null, disponibleEnAplicacion: true },
];

export default rutasApp;
