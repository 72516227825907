const mensajes = {
    // TablaDetallesPlantilla
    'TablaDetallesPlantilla.CodigoBarras': 'Item barcode',
    'TablaDetallesPlantilla.DescripcionItem': 'Item description',
    'TablaDetallesPlantilla.CantidadSolicitada': 'Requested quantity',
    'TablaDetallesPlantilla.PesoFraccion': 'Weight',
    'TablaDetallesPlantilla.PesoFraccionSolicitado': 'Requested Weight',
    'TablaDetallesPlantilla.PesoFraccionRecibido': 'Received Weight',
    'TablaDetallesPlantilla.CantidadRecibida': 'Received quantity',
    'TablaDetallesPlantilla.CantidadEntregada': 'Delivered quantity',
    'TablaDetallesPlantilla.Cantidad': 'Quantity',
    'TablaDetallesPlantilla.Lote': 'Lot',
    'TablaDetallesPlantilla.FechaVencimiento': 'Expiration date',
    'TablaDetallesPlantilla.DivisionOrigen': 'Origin division',
    'TablaDetallesPlantilla.DivisionDestino': 'Destination division',
    'TablaDetallesPlantilla.AsignarSeriales': 'Assign serials',
    'TablaDetallesPlantilla.ValidacionCantidadMayorASolicitada': 'Quantity can not be greater than requested quantity',
    'TablaDetallesPlantilla.ValidacionNoFechaVencimiento': 'This item requires expiration date',
    'TablaDetallesPlantilla.CantidadActual': 'Quantity current',
    'TablaDetallesPlantilla.CantidadDisponible': 'Quantity Available',
    'TablaDetallesPlantilla.FechaIngreso': 'Admission date',
    'TablaDetallesPlantilla.SinDivisionDestino': 'There are no divisions for transfer',
    'TablaDetallesPlantilla.PesoFraccionStock': 'Stock Weight',
    'TablaDetallesPlantilla.CantidadStock': 'Stock Quantity',
    // CampoCantidad
    'TablaDetallesPlantilla.CampoCantidad.Nombre': 'Quantity',
    'TablaDetallesPlantilla.CampoPesoSolicitado': 'Requested weight',
    'TablaDetallesPlantilla.PesoFraccionEntregado': 'Delivered weight',
    'TablaDetallesPlantilla.PesoDisponible': 'Available weight',
    'TablaDetallesPlantilla.PesoUnidad':'Weight Unit',
};

export default mensajes;
