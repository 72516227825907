import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';

interface ICampoDocumentoRelacionadoProps {
    deshabilitado: boolean;
    manejarCambioCampo: (nombreCampo: keyof IDocumento, nuevoValor: IDocumento[keyof IDocumento]) => any;
    valor: string;
}

const CampoDocumentoRelacionado: FunctionComponent<ICampoDocumentoRelacionadoProps> = ({
    deshabilitado,
    manejarCambioCampo,
    valor,
}) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="FormularioDocumento.CampoDocumentoRelacionado.Nombre" />}
        onChange={(event) => manejarCambioCampo('DocumentoRelacionado', event.target.value)}
        value={valor}
    />
);

export default CampoDocumentoRelacionado;
