import { Grid } from '@material-ui/core';
import React, { createRef, FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import { IPaginador, IRespuestaPaginada } from '@infotrack/presentacion-transversales/interfacesComunes';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IClientes from 'Infotrack@Modelos/smartStock/clientes/entidades/clientes';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';
import IProveedores from 'Infotrack@Modelos/smartStock/proveedores/entidades/proveedores';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';

import DocumentosFiltros from './DocumentosFiltros/DocumentosFiltros';
import DocumentosTabla from './DocumentosTabla/DocumentosTabla';

interface IEntidadesDocumentos {
    bodegasOrigen?: IBodega[];
    bodegasDestino?: IBodega[];
    clientes?: IClientes[];
    etapas: IEtapas[];
    proveedores?: IProveedores[];
    tiposDocumento: ITipoDocumento[];
}

interface IDocumentosProps {
    agregarDocumento: () => void;
    anularDocumento: (documentoId: string) => void;
    editarDocumento: (documentoId: string) => any;
    ejecutarConsultarDocumentos: boolean;
    entidadesDocumento: Partial<IEntidadesDocumentos>;
    camposOcultarFiltros: Array<keyof IDocumentoFiltro>;
    camposOcultarTabla: Array<keyof IDocumentoConsultaGeneral>;
    consultarDocumentos: (
        paginador: IPaginador,
        filtro: IDocumentoFiltro
    ) => Promise<IRespuestaPaginada<IDocumentoConsultaGeneral>>;
    verDocumento: (documentoId: string) => any;
    descargarDocumentoPdf: (documento: IDocumentoConsultaGeneral) => any;
    consultarTipoDocumentoBodega: (idBodega: number) => void;
    limpiarFiltroTipoDocumento: () => void;
    abrirFormularioCargaMasiva?: () => void;
    descargarPlantilla?: () => void
}

const FILTROS_POR_DEFECTO: IDocumentoFiltro = {
    Cliente: '',
    CodigoDocumento: '',
    CodigoDocumentoPredecesor: '',
    CondicionComercial: '',
    DireccionEntrega: '',
    DocumentoId: '',
    DocumentoRelacionado: '',
    Estado: 0,
    EtapaId: 0,
    FechaCreacion: '',
    FlujoId: 0,
    Observacion: '',
    PersonaRelacionada: '',
    Proveedor: '',
    SincronizacionMovil: false,
    TipoDocumentoId: 0,
    Serial: ''
};

const Documentos: FunctionComponent<IDocumentosProps> = ({
    agregarDocumento,
    anularDocumento,
    editarDocumento,
    ejecutarConsultarDocumentos,
    entidadesDocumento,
    camposOcultarFiltros,
    camposOcultarTabla,
    consultarDocumentos: consultarDocumentosProp,
    verDocumento,
    descargarDocumentoPdf,
    consultarTipoDocumentoBodega,
    limpiarFiltroTipoDocumento,
    abrirFormularioCargaMasiva,
    descargarPlantilla
}) => {
    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);

    const [filtros, setFiltros] = useState<IDocumentoFiltro>(FILTROS_POR_DEFECTO);
    const [hayBodegaOrigenFiltro, setHayBodegaOrigenFiltro] = useState<boolean>(false);

    const esPrimeraCarga = useRef(true);

    const referenciaTabla = createRef<any>();

    useEffect(() => {
        if (esPrimeraCarga.current) {
            validarTipoBodega();
            esPrimeraCarga.current = false;
        } else {
            consultarDocumentos();
        }
    }, [ejecutarConsultarDocumentos]);

    const manejarCambioFiltro = (
        nombreFiltro: keyof IDocumentoFiltro,
        nuevoValor: IDocumentoFiltro[keyof IDocumentoFiltro]
    ) => {
        setFiltros((filtrosActuales) => ({ ...filtrosActuales, [nombreFiltro]: nuevoValor }));
        if (hayBodegaOrigenFiltro && nombreFiltro === 'BodegaOrigen') {
            consultarTipoDocumentoBodega(nuevoValor! as number);
        }
        if (!hayBodegaOrigenFiltro && nombreFiltro === 'BodegaDestino') {
            consultarTipoDocumentoBodega(nuevoValor as number);
        }
    };

    const validarTipoBodega = () => {
        camposOcultarFiltros.includes('BodegaOrigen')
            ? setHayBodegaOrigenFiltro(false)
            : setHayBodegaOrigenFiltro(true);
    };

    const mapearFiltros = (): IDocumentoFiltro => {
        return {
            ...filtros,
            EmpresaId: idEmpresa,
            AgenciaId: idAgencia,
            IdUsuario: idUsuario,
        };
    };

    const consultaDocumentos = paginacionRemota<IDocumentoConsultaGeneral>(
        consultarDocumentosProp,
        mapearFiltros(),
        'FechaCreacion'
    );

    const consultarDocumentos = () => {
        referenciaTabla.current.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <DocumentosFiltros
                    aplicarFiltros={consultarDocumentos}
                    camposOcultar={camposOcultarFiltros}
                    entidades={entidadesDocumento}
                    filtros={filtros}
                    limpiarFiltros={() => {
                        setFiltros(FILTROS_POR_DEFECTO);
                        consultarDocumentos();
                        limpiarFiltroTipoDocumento()
                    }}
                    manejarCambioFiltro={manejarCambioFiltro}
                />
            </Grid>
            <Grid item xs={12}>
                <DocumentosTabla
                    agregarDocumento={agregarDocumento}
                    anularDocumento={anularDocumento}
                    camposOcultar={camposOcultarTabla}
                    consulta={consultaDocumentos}
                    consultarDocumentos={consultarDocumentos}
                    editarDocumento={editarDocumento}
                    referenciaTabla={referenciaTabla}
                    verDocumento={verDocumento}
                    descargarDocumentoPdf={descargarDocumentoPdf}
                    abrirFormularioCargaMasiva={abrirFormularioCargaMasiva}
                    descargarPlantilla={descargarPlantilla}
                />
            </Grid>
        </Grid>
    );
};

export { IEntidadesDocumentos };

export default Documentos;
