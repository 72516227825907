import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    manejadorDialogoGlobal,
    manejadorIndicadorCargaGlobal,
} from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import Texto from '@infotrack/presentacion-componentes/texto';

import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import Documentos, { IEntidadesDocumentos } from 'Infotrack@Transversales/componentes/Documentos/Documentos';
import GenerarPDF from 'Infotrack@Transversales/componentes/archivoPDF/GenerarPDF';
import { EstadosModalArchivoPDF } from 'Infotrack@Transversales/componentes/archivoPDF/enumeraciones';
import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import { ChangeEvent } from 'react';
import * as XLSX from 'xlsx';
import { CAMPOS_OCULTAR_DOCUMENTOS_FILTROS, CAMPOS_OCULTAR_DOCUMENTOS_TABLA } from './constantes';
import EntidadesDocumentoCM from './controladorModelo/EntidadesDocumentoCM';
import EntradaInventarioCM from './controladorModelo/EntradaInventarioCM';
import { EstadosModalEntradaInventario } from './enumeraciones';
import ModalEntradaInventario from './ModalEntradaInventario';
import FormularioCargaPlantillaInventario from 'Infotrack@Transversales/componentes/CargaMasiva/FormularioCargaPlantillaInventario/FormularioCargaPlantillaInventario';
import ModeloCargarPlantilla from './controladorModelo/cargarPlantillaCM';
import TablaErrores from 'Infotrack@Transversales/componentes/CargaMasiva/TablaErrores/TablaErrores';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';
import IPlantilla from 'Infotrack@Modelos/smartStock/plantilla/entidades/IPlantilla';
import ModeloPlantillaServicioCM from './controladorModelo/plantillaServicioCM';

const EntradaInventario = () => {
    const entidadesDocumentoCM = useMemo(() => new EntidadesDocumentoCM(), []);
    const entradaInventarioCM = useMemo(() => new EntradaInventarioCM(), []);
    const modeloCargarPlantilla = new ModeloCargarPlantilla();
    const modeloPlantillaServicioCM = new ModeloPlantillaServicioCM();

    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);

    const [entidadesDocumento, setEntidadesDocumento] = useState<Partial<IEntidadesDocumentos>>({});
    const [estadoModal, setEstadoModal] = useState<EstadosModalEntradaInventario>(
        EstadosModalEntradaInventario.CERRADO
    );
    const [estadoArchivoPDF, setEstadoArchivoPDF] = useState<EstadosModalArchivoPDF>(EstadosModalArchivoPDF.CERRADO);
    const [documentoId, setDocumentoId] = useState<string | null>(null);
    const [ejecutarConsultarDocumentos, setEjecutarConsultarDocumentos] = useState<boolean>(false);
    const [archivoPDF, setArchivoPDF] = useState<string | null>(null);
    const [estadoFormularioCargaMasiva, setFormularioCargaMasiva] = useState<boolean>(false);
    const [archivoCargaMasiva, setArchivoCargaMasiva] = useState<File>();
    const [idbodegaSeleccionada, setIdBodegaSeleccionada] = useState<number>(0);
    const [idTipoDocumentoSeleccionado, setIdTipoDocumentoSeleccionado] = useState<number>(0);
    const [bodegas, setBodegas] = useState<IBodega[]>([]);
    const [tipoDocumentos, setTipoDocumentos] = useState<ITipoDocumento[]>([]);

    const esPrimeraCarga = useRef(true);

    useEffect(() => {
        consultarEntidadesFiltros();
    }, []);

    useEffect(() => {
        if (estadoFormularioCargaMasiva) {
            consultarBodegas();
        }
    }, [estadoFormularioCargaMasiva]);

    useEffect(() => {
        if (idbodegaSeleccionada !== 0) {
            consultarTipoDocumentoFormularioCarga(idbodegaSeleccionada);
        }
    }, [idbodegaSeleccionada]);

    useEffect(() => {
        if (esPrimeraCarga.current) {
            esPrimeraCarga.current = false;
        } else {
            if (estadoModal === EstadosModalEntradaInventario.CERRADO)
                setEjecutarConsultarDocumentos(
                    (ejecutarConsultarDocumentosActual) => !ejecutarConsultarDocumentosActual
                );
        }
    }, [estadoModal]);

    const consultarBodegas = async () => {
        const bodegas = await entidadesDocumentoCM.consultarBodegasPersona(idAgencia, idUsuario, idEmpresa);
        setBodegas(bodegas);
    };

    const consultarEntidadesFiltros = async () => {
        const entidadesDocumentoConsultadas = await entidadesDocumentoCM.consultarEntidadesDocumentoFiltro(
            idEmpresa,
            idAgencia,
            idUsuario
        );
        setEntidadesDocumento(entidadesDocumentoConsultadas);
    };

    const verDocumento = (nuevoDocumentoId: string) => {
        setDocumentoId(nuevoDocumentoId);
        setEstadoModal(EstadosModalEntradaInventario.VISUALIZACION);
    };

    const editarDocumento = (nuevoDocumentoId: string) => {
        setDocumentoId(nuevoDocumentoId);
        setEstadoModal(EstadosModalEntradaInventario.EDICION);
    };

    const generarArchivoPDF = async (documento: IDocumentoConsultaGeneral) => {
        manejadorIndicadorCargaGlobal(true);
        const respuestaArchivoPDF = await entradaInventarioCM.generarArchivoPDF(
            documento.DocumentoId,
            documento.TipoDocumentoId
        );
        setArchivoPDF(respuestaArchivoPDF);
        setEstadoArchivoPDF(EstadosModalArchivoPDF.VISUALIZACION);
        manejadorIndicadorCargaGlobal(false);
    };

    const confirmarAnularDocumento = (documentoIdAnular: string) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => anularDocumento(documentoIdAnular),
            estado: true,
            mensaje: <Texto id={'MensajeEntradaInventario.confirmarAnularDocumento'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const anularDocumento = async (documentoId: string) => {
        const anuladoExitoso = await entradaInventarioCM.anularDocumento(documentoId);
        if (anuladoExitoso) {
            setEjecutarConsultarDocumentos(!ejecutarConsultarDocumentos);
            manejadorDialogoGlobal({ estado: false });
        }
    };

    const consultarTipoDocumento = async (idBodega: number) => {
        const tiposDocumentosBodega = await entidadesDocumentoCM.consultarTipoDocumento(idBodega);
        setEntidadesDocumento({ ...entidadesDocumento, tiposDocumento: tiposDocumentosBodega });
    };

    const consultarTipoDocumentoFormularioCarga = async (idBodega: number) => {
        const tiposDocumentos = await entidadesDocumentoCM.consultarTipoDocumento(idBodega);
        setTipoDocumentos(tiposDocumentos);
    };
    const handleCargarArchivo = (event: ChangeEvent<HTMLInputElement>) => {
        const archivo = event.target.files![0];

        const lector = new FileReader();
        setArchivoCargaMasiva(archivo);
        lector.onload = function(e) {
            const contenidoArchivo = e.target!.result;
            const libro = XLSX.read(contenidoArchivo, { type: 'array' });
            const hoja = libro.SheetNames[0];
            const datos = XLSX.utils.sheet_to_json(libro.Sheets[hoja]);
            const datosBinarios = XLSX.write(libro, { type: 'array', bookType: 'xlsx' });
            const archivoBinario = new Blob([datosBinarios], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
        };

        lector.readAsBinaryString(archivo);
    };

    const CargarPlantilla = async () => {
        if (
            modeloCargarPlantilla.validarEntidad(idbodegaSeleccionada, idTipoDocumentoSeleccionado, archivoCargaMasiva)
        ) {
            const formData = new FormData();
            formData.append('Archivo', archivoCargaMasiva!, archivoCargaMasiva!.name);
            formData.append(
                'Datos',
                JSON.stringify({
                    IdEmpresa: idEmpresa,
                    IdAgencia: idAgencia,
                    SincronizaMovil: tipoDocumentos.find((x) => x.TipoDocumentoId === idTipoDocumentoSeleccionado)!
                        .SincronizaMovil,
                    IdUsuario: idUsuario,
                    IdBodega: idbodegaSeleccionada,
                    IdTipoDocumento: idTipoDocumentoSeleccionado,
                })
            );
            const validacionesCargaProducto = (await modeloCargarPlantilla.CargarPlantillaInventario(formData)).data
                .Entidades;
            if (validacionesCargaProducto.length >= 1) {
                manejadorDialogoGlobal({
                    estado: true,
                    mostrarCancelar: false,
                    accionConfirmar: () => manejadorDialogoGlobal({ estado: false }),
                    mensaje: (
                        <div>
                            <TablaErrores listaErrores={validacionesCargaProducto} />
                        </div>
                    ),
                    tipoDialogo: 'Error',
                    titulo: <Texto id="cargaMasiva.Errores" style={{ textAlign: 'center' }} />,
                });
            }else{
                setFormularioCargaMasiva(false);
                setEjecutarConsultarDocumentos(!ejecutarConsultarDocumentos);
                setIdTipoDocumentoSeleccionado(0);
                setIdBodegaSeleccionada(0);
            }
        }
    };

    const cerrarFormularioCarga = () => {
        setIdBodegaSeleccionada(0);
        setArchivoCargaMasiva(undefined);
        setFormularioCargaMasiva(false);
        setIdTipoDocumentoSeleccionado(0);
        setTipoDocumentos([]);
    };
    const abrirFormularioCarga = () => {
        setFormularioCargaMasiva(true);
    };
    const descargarArchivoExcel = async () => {
        const parametros: IPlantilla = {
            Extension: 'xlsx',
            NombreArchivo: 'FormatoInventario',
        };
        const respuesta = await modeloPlantillaServicioCM.DescargarPlantilla(parametros);
        const blob = new Blob([respuesta], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const urlArchivo = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlArchivo;
        link.download = 'FormatoInventario.xlsx';
        link.click();
        URL.revokeObjectURL;
    };

    return (
        <>
            <Documentos
                agregarDocumento={() => setEstadoModal(EstadosModalEntradaInventario.CREACION)}
                anularDocumento={confirmarAnularDocumento}
                camposOcultarFiltros={CAMPOS_OCULTAR_DOCUMENTOS_FILTROS}
                camposOcultarTabla={CAMPOS_OCULTAR_DOCUMENTOS_TABLA}
                consultarDocumentos={entradaInventarioCM.consultarListaDocumentos}
                editarDocumento={editarDocumento}
                ejecutarConsultarDocumentos={ejecutarConsultarDocumentos}
                entidadesDocumento={entidadesDocumento}
                verDocumento={verDocumento}
                descargarDocumentoPdf={generarArchivoPDF}
                consultarTipoDocumentoBodega={consultarTipoDocumento}
                limpiarFiltroTipoDocumento={() => setEntidadesDocumento({ ...entidadesDocumento, tiposDocumento: [] })}
                descargarPlantilla={descargarArchivoExcel}
                abrirFormularioCargaMasiva={abrirFormularioCarga}
            />
            <ModalEntradaInventario
                cerrar={() => {
                    setEstadoModal(EstadosModalEntradaInventario.CERRADO), setDocumentoId(null);
                }}
                documentoId={documentoId}
                establecerDocumentoId={(nuevoDocumentoId: string) => setDocumentoId(nuevoDocumentoId)}
                estado={estadoModal}
                descargarDocumentoPdf={generarArchivoPDF}
                cambiarEstado={() => setEstadoModal(EstadosModalEntradaInventario.EDICION)}
            />
            {archivoPDF && (
                <GenerarPDF
                    cerrar={() => setEstadoArchivoPDF(EstadosModalArchivoPDF.CERRADO)}
                    archivoPDF={archivoPDF}
                    estado={estadoArchivoPDF}
                />
            )}
            <FormularioCargaPlantillaInventario
                cerrar={cerrarFormularioCarga}
                enviarArchivoCarga={CargarPlantilla}
                estado={estadoFormularioCargaMasiva}
                handleCargarArchivo={handleCargarArchivo}
                idBodegaSeleccionada={idbodegaSeleccionada}
                listaBodegas={bodegas}
                setBodega={(idBodegaSeleccionada: number) => {
                    setIdBodegaSeleccionada(idBodegaSeleccionada),
                        setIdTipoDocumentoSeleccionado(0),
                        setTipoDocumentos([]);
                }}
                setTipoDocumento={(IdTipoDocumentoSeleccionado: number) =>
                    setIdTipoDocumentoSeleccionado(IdTipoDocumentoSeleccionado)
                }
                listaTipoDocumento={tipoDocumentos}
                idTipoDocumento={idTipoDocumentoSeleccionado}
            />
        </>
    );
};

export default EntradaInventario;
