import ModeloTipoDocumento from '../../../../modelos/negocioRefactor/modelos/Administracion/ModeloTipoDocumento';
import ModeloBodegas from '../../../../modelos/smartStock/bodegas';
import ModeloEtapas from '../../../../modelos/smartStock/etapas';
import ModeloProveedor from '../../../../modelos/smartStock/proveedores';
import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';
import ModeloUsuario from 'Infotrack@Modelos/administracion/usuario';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';

class EntidadesDocumentoCM {
    private modeloTipoDocumento: ModeloTipoDocumento;
    private modeloEtapa: ModeloEtapas;
    private modeloProveedor: ModeloProveedor;
    private modeloBodega: ModeloBodegas;
    private modeloUsuario: ModeloUsuario;

    constructor() {
        this.modeloTipoDocumento = new ModeloTipoDocumento();
        this.modeloEtapa = new ModeloEtapas();
        this.modeloProveedor = new ModeloProveedor();
        this.modeloBodega = new ModeloBodegas();
        this.modeloUsuario = new ModeloUsuario();
    }

    public async consultarEntidadesDocumento(
        idEmpresa: string,
        idAgencia: string,
        idUsuario: string,
        estado?: number,
        idPerfil?: string
    ) {
        const respuestas = await Promise.all([
            this.modeloEtapa.ConsultarListaEtapasPorFiltro({ EmpresaId: idEmpresa, Estado: estado }),
            this.modeloProveedor.consultarListaProveedoresPorFiltro({ EmpresaId: idEmpresa, Estado: estado }),
            this.modeloBodega.consultarBodegasPersonasDoc({
                AgenciaId: idAgencia,
                UsuarioId: idUsuario,
                EmpresaId: idEmpresa,
                Estado: estado,
            }),
            this.modeloUsuario.consultarListaUsuarioPersonaEmpresaPerfil(idEmpresa, idPerfil!),
        ]);
        if (respuestas[3].data.Entidades.length === 0) {
            notificacionGlobal('FormularioDocumento.ConsultaSinResponsables', 6000, 'warning', true);
        }
        return {
            etapas: respuestas[0].data.Entidades,
            proveedores: respuestas[1].data.Entidades,
            bodegasOrigen: respuestas[2].data.Entidades,
            bodegasDestino: respuestas[2].data.Entidades,
            responsables: respuestas[3].data.Entidades,
        };
    }

    public async consultarEntidadesDocumentoEdicion(
        idPerfil: string,
        idBodega: number,
        idEmpresa: string,
        idAgencia: string,
        tipoProcesoId: number,
        estado?: number
    ) {
        const respuestas = await Promise.all([
            this.modeloTipoDocumento.consultarTiposDocumentoFiltro({
                BodegaId: idBodega,
                Estado: estado,
                TipoProcesoId:tipoProcesoId
            }),
            this.modeloEtapa.ConsultarListaEtapasPorFiltro({ EmpresaId: idEmpresa, Estado: estado }),
            this.modeloProveedor.consultarListaProveedoresPorFiltro({ EmpresaId: idEmpresa, Estado: estado }),
            this.modeloBodega.consultarBodegasPersonasDoc({
                AgenciaId: idAgencia,
                EmpresaId: idEmpresa,
                Estado: estado,
            }),
            this.modeloUsuario.consultarListaUsuarioPersonaEmpresaPerfil(idEmpresa, idPerfil),
        ]);
        if (respuestas[4].data.Entidades.length === 0) {
            notificacionGlobal('FormularioDocumento.ConsultaSinResponsables', 6000, 'warning', true);
        }
        return {
            tiposDocumento: respuestas[0].data.Entidades,
            etapas: respuestas[1].data.Entidades,
            proveedores: respuestas[2].data.Entidades,
            bodegasOrigen: respuestas[3].data.Entidades,
            bodegasDestino: respuestas[3].data.Entidades,
            responsables: respuestas[4].data.Entidades,
        };
    }

    public async consultarTipoDocumento(idBodega: number, estado?: number) {
        const respuestas = await Promise.all([
            this.modeloTipoDocumento.consultarTiposDocumentoFiltro({
                BodegaId: idBodega,
                TipoProcesoId: TiposProceso.TRASLADO,
                Estado: estado,
            }),
        ]);
        return respuestas[0].data.Entidades;
    }

    public async consultarEntidadesDocumentoFiltro(
        idEmpresa: string,
        idAgencia: string,
        idUsuario: string,
        tipoProcesoId: number,
        estado?: number
    ) {
        const respuestas = await Promise.all([
            this.modeloTipoDocumento.consultarTiposDocumentoFiltro({
                TipoProcesoId: tipoProcesoId,
                EmpresaId: idEmpresa,
                AgenciaId: idAgencia,
                Estado: estado,
            }),
            this.modeloEtapa.ConsultarListaEtapasPorFiltro({ EmpresaId: idEmpresa, Estado: estado }),
            this.modeloProveedor.consultarListaProveedoresPorFiltro({ EmpresaId: idEmpresa, Estado: estado }),
            this.modeloBodega.consultarBodegasPersonasDoc({
                AgenciaId: idAgencia,
                UsuarioId: idUsuario,
                EmpresaId: idEmpresa,
                Estado: estado,
            }),
        ]);
        return {
            tiposDocumento: respuestas[0].data.Entidades,
            etapas: respuestas[1].data.Entidades,
            proveedores: respuestas[2].data.Entidades,
            bodegasOrigen: respuestas[3].data.Entidades,
            bodegasDestino: respuestas[3].data.Entidades,
        };
    }
}

export default EntidadesDocumentoCM;
