const mensajes = {
    // OrdenSalida
    'OrdenSalida.ValidacionNoTipoDocumentoId': 'Debe seleccionar un tipo de documento',
    'OrdenSalida.ValidacionNoBodegaOrigen': 'Debe seleccionar una bodega de origen',
    'OrdenSalida.ValidacionNoClienteId': 'Debe seleccionar un cliente',
    'OrdenSalida.ValidacionNoResponsableId':'Debe seleccionar un responsable',
    // ModalOrdenSalida
    'ModalOrdenSalida.TituloCreacion': 'Crear Orden de Salida',
    'ModalOrdenSalida.TituloEdicion': 'Editar Orden de Salida',
    'ModalOrdenSalida.TituloVisualizacion': 'Ver Orden de Salida',
    'ModalOrdenSalida.TituloTablaDetalles': 'Detalles',
    'ModalOrdenSalida.TituloValorTotalDocumento': 'Total documento',
    'permiso.ordensalida': 'Acceso',
    // mensajes
    'MensajeOrdenSalida.confirmarAnularDocumento': '¿Está seguro que desea anular el documento?',
};

export default mensajes;
