const mensajes = {
    // TablaDetalles
    'TablaDetalles.CodigoBarras': 'Item barcode',
    'TablaDetalles.DescripcionItem': 'Item description',
    'TablaDetalles.Lote': 'Lot',
    'TablaDetalles.Peso': 'Weight',
    'TablaDetalles.PesoFraccionSolicitado': 'Requested Weight',
    'TablaDetalles.PesoFraccionRecibido': 'Received Weight',
    'TablaDetalles.FechaVencimiento': 'Expiration date',
    'TablaDetalles.FechaIngreso': 'Admission date',
    'TablaDetalles.DescripcionDivisionOrigen': 'Origin division',
    'TablaDetalles.DescripcionDivisionDestino': 'Destination division',
    'TablaDetalles.CantidadSolicitada': 'Requested quantity',
    'TablaDetalles.CantidadRecibida': 'Received quantity',
    'TablaDetalles.CantidadEntregada': 'Delivered quantity',
    'TablaDetalles.Valor': 'Value',
    'TablaDetalles.Serial': 'Serial',
    'TablaDetalles.Piezas': 'Pieces',
    // ModalEdicionDetalle
    'ModalEdicionDetalle.Titulo': 'Edit detail',
    'ModalEdicionDetalle.CodigoBarrasItem': 'Item barcode',
    'ModalEdicionDetalle.DescripcionItem': 'Item description',
    'ModalEdicionDetalle.DivisionOrigen': 'Origin division',
    'ModalEdicionDetalle.DivisionDestino': 'Destination division',
    'ModalEdicionDetalle.CantidadRecibida': 'Received quantity',
    'ModalEdicionDetalle.CantidadEntregada': 'Delivered quantity',
    'ModalEdicionDetalle.Serial': 'Serial',
    'ModalEdicionDetalle.Lote': 'Lot',
    'ModalEdicionDetalle.FechaVencimiento': 'Expiration date',
    'TablaDocumentos.DescargarDocumento':'View PDF'
};

export default mensajes;
