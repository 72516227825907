const mensajes = {
    // EntradaInventario
    'EntradaInventario.ValidacionNoTipoDocumentoId': 'Debe seleccionar un tipo de documento',
    'EntradaInventario.ValidacionNoProveedorId': 'Debe seleccionar un proveedor',
    'EntradaInventario.ValidacionNoBodegaDestino': 'Debe seleccionar una bodega de destino',
    'EntradaInventario.ValidacionCantidadRecibidaCero': 'La cantidad recibida debe ser mayor a 0',
    'EntradaDevolucion.ValidacionNoResponsableId':'Debe seleccionar un responsable',
    'EntradaInventario.ValidacionPesoFraccionCero': 'El peso recibido debe ser mayor a 0',
    // ModalEntradaInventario
    'ModalEntradaInventario.TituloCreacion': 'Crear Entrada de Inventario',
    'ModalEntradaInventario.TituloEdicion': 'Editar Entrada de Inventario',
    'ModalEntradaInventario.TituloVisualizacion': 'Ver Entrada de Inventario',
    'ModalEntradaInventario.TituloTablaDetallesDocumento': 'Detalles Documento',
    'ModalEntradaInventario.TituloTablaDetallesDocumentoBase': 'Detalles Documento Base',
    'permiso.entradainventario': 'Acceso',
    // mensajes
    'MensajeEntradaInventario.confirmarAnularDocumento': '¿Está seguro que desea anular el documento?',
};

export default mensajes;
