const mensajes = {
    'FormularioDocumento.Titulo': 'Document',
    'FormularioDocumento.CambiarEtapa': 'Change stage',
    'FormularioDocumento.CampoCodigoDocumentoBase.Nombre': 'Base doc. code',
    'FormularioDocumento.CampoTipoDocumentoId.Nombre': 'Document type',
    'FormularioDocumento.CampoEtapaId.Nombre': 'Stage',
    'FormularioDocumento.CampoProveedorId.Nombre': 'Supplier',
    'FormularioDocumento.CampoCodigoDocumento.Nombre': 'Code',
    'FormularioDocumento.CampoDireccionEntrega.Nombre': 'Delivery address',
    'FormularioDocumento.CampoFechaEntrega.Nombre': 'Delivery date',
    'FormularioDocumento.CampoCondicionComercial.Nombre': 'Commercial condition',
    'FormularioDocumento.CampoBodegaOrigen.Nombre': 'Origin warehouse',
    'FormularioDocumento.CampoBodegaDestino.Nombre': 'Destination warehouse',
    'FormularioDocumento.CampoDocumentoRelacionado.Nombre': 'Related doc.',
    'FormularioDocumento.CampoObservacion.Nombre': 'Observation',
    'FormularioDocumento.CampoClienteId.Nombre': 'Client',
    "FormularioDocumento.DescargarDocumento": 'View PDF',
    "FormularioDocumento.SinRegistros": 'No records found',
    "FormularioDocumento.ResponsableActual": 'Current Manager',
    "FormularioDocumento.NuevoResponsable":"New Responsible",
    "FormularioDocumento.ResponsableInactivo":"Responsable inactive",
    'FormularioDocumento.ConsultaSinResponsables':"No users with brand manager profile were found, check with the administrator."
};

export default mensajes;
