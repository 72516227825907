import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';

interface ICampoObservacionProps {
    deshabilitado: boolean;
    manejarCambioCampo: (nombreCampo: keyof IDocumento, nuevoValor: IDocumento[keyof IDocumento]) => any;
    valor: string;
}

const CampoObservacion: FunctionComponent<ICampoObservacionProps> = ({ deshabilitado, manejarCambioCampo, valor }) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="FormularioDocumento.CampoObservacion.Nombre" />}
        onChange={(event) => manejarCambioCampo('Observacion', event.target.value)}
        value={valor}
    />
);

export default CampoObservacion;
