import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';

import { BotonBuscar, BotonLimpiar } from '../../botonesAcciones';
import { IEntidadesDocumentos } from '../Documentos';
import CampoBodegaDestino from './Campos/CampoBodegaDestino';
import CampoBodegaOrigen from './Campos/CampoBodegaOrigen';
import CampoCliente from './Campos/CampoCliente';
import CampoClienteId from './Campos/CampoClienteId';
import CampoCodigoDocumento from './Campos/CampoCodigoDocumento';
import CampoCodigoDocumentoPredecesor from './Campos/CampoCodigoDocumentoPredecesor';
import CampoDocumentoRelacionado from './Campos/CampoDocumentoRelacionado';
import CampoEtapaId from './Campos/CampoEtapaId';
import CampoFechaEntrega from './Campos/CampoFechaEntrega';
import CampoFechaFinal from './Campos/CampoFechaFinal';
import CampoFechaInicial from './Campos/CampoFechaInicial';
import CampoProveedor from './Campos/CampoProveedor';
import CampoProveedorId from './Campos/CampoProveedorId';
import CampoTipoDocumentoId from './Campos/CampoTipoDocumentoId';

interface IDocumentosFiltrosProps {
    aplicarFiltros: () => void;
    camposOcultar?: Array<keyof IDocumentoFiltro>;
    entidades: Partial<IEntidadesDocumentos>;
    filtros: IDocumentoFiltro;
    limpiarFiltros: () => void;
    manejarCambioFiltro: (
        nombreFiltro: keyof IDocumentoFiltro,
        nuevoValor: IDocumentoFiltro[keyof IDocumentoFiltro]
    ) => void;
}

const DocumentosFiltros: FunctionComponent<IDocumentosFiltrosProps> = ({
    aplicarFiltros,
    camposOcultar = [],
    entidades,
    filtros,
    limpiarFiltros,
    manejarCambioFiltro,
}) => (
    <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Texto fontWeight="bold" mensaje="Filtros" />
        </AccordionSummary>
        <AccordionDetails>
            <Grid container spacing={2}>
                {!camposOcultar.includes('BodegaOrigen') && (
                    <Grid item md={3} xs={12}>
                        <CampoBodegaOrigen
                            bodegasOrigen={entidades.bodegasOrigen || []}
                            manejarCambioFiltro={manejarCambioFiltro}
                            valor={filtros.BodegaOrigen}
                        />
                    </Grid>
                )}
                {!camposOcultar.includes('BodegaDestino') && (
                    <Grid item md={3} xs={12}>
                        <CampoBodegaDestino
                            bodegasDestino={entidades.bodegasDestino || []}
                            manejarCambioFiltro={manejarCambioFiltro}
                            valor={filtros.BodegaDestino}
                        />
                    </Grid>
                )}
                {!camposOcultar.includes('TipoDocumentoId') && (
                    <Grid item md={3} xs={12}>
                        <CampoTipoDocumentoId
                            manejarCambioFiltro={manejarCambioFiltro}
                            tiposDocumento={entidades.tiposDocumento || []}
                            valor={filtros.TipoDocumentoId}
                        />
                    </Grid>
                )}
                {!camposOcultar.includes('EtapaId') && (
                    <Grid item md={3} xs={12}>
                        <CampoEtapaId
                            etapas={entidades.etapas || []}
                            manejarCambioFiltro={manejarCambioFiltro}
                            valor={filtros.EtapaId}
                        />
                    </Grid>
                )}
                {!camposOcultar.includes('ProveedorId') && (
                    <Grid item md={3} xs={12}>
                        <CampoProveedorId
                            manejarCambioFiltro={manejarCambioFiltro}
                            proveedores={entidades.proveedores || []}
                            valor={filtros.ProveedorId}
                        />
                    </Grid>
                )}
                {!camposOcultar.includes('Proveedor') && (
                    <Grid item md={3} xs={12}>
                        <CampoProveedor manejarCambioFiltro={manejarCambioFiltro} valor={filtros.Proveedor} />
                    </Grid>
                )}
                {!camposOcultar.includes('CodigoDocumento') && (
                    <Grid item md={3} xs={12}>
                        <CampoCodigoDocumento
                            manejarCambioFiltro={manejarCambioFiltro}
                            valor={filtros.CodigoDocumento}
                        />
                    </Grid>
                )}
                {!camposOcultar.includes('FechaEntrega') && (
                    <Grid item md={3} xs={12}>
                        <CampoFechaEntrega manejarCambioFiltro={manejarCambioFiltro} valor={filtros.FechaEntrega} />
                    </Grid>
                )}

                {!camposOcultar.includes('DocumentoRelacionado') && (
                    <Grid item md={3} xs={12}>
                        <CampoDocumentoRelacionado
                            manejarCambioFiltro={manejarCambioFiltro}
                            valor={filtros.DocumentoRelacionado}
                        />
                    </Grid>
                )}
                {!camposOcultar.includes('ClienteId') && (
                    <Grid item md={3} xs={12}>
                        <CampoClienteId
                            clientes={entidades.clientes || []}
                            manejarCambioFiltro={manejarCambioFiltro}
                            valor={filtros.ClienteId}
                        />
                    </Grid>
                )}
                {!camposOcultar.includes('Cliente') && (
                    <Grid item md={3} xs={12}>
                        <CampoCliente manejarCambioFiltro={manejarCambioFiltro} valor={filtros.Cliente} />
                    </Grid>
                )}
                {!camposOcultar.includes('FechaInicial') && (
                    <Grid item md={3} xs={12}>
                        <CampoFechaInicial manejarCambioFiltro={manejarCambioFiltro} valor={filtros.FechaInicial} />
                    </Grid>
                )}
                {!camposOcultar.includes('FechaFinal') && (
                    <Grid item md={3} xs={12}>
                        <CampoFechaFinal manejarCambioFiltro={manejarCambioFiltro} valor={filtros.FechaFinal} />
                    </Grid>
                )}
                {!camposOcultar.includes('CodigoDocumentoPredecesor') && (
                    <Grid item md={3} xs={12}>
                        <CampoCodigoDocumentoPredecesor
                            manejarCambioFiltro={manejarCambioFiltro}
                            valor={filtros.CodigoDocumentoPredecesor}
                        />
                    </Grid>
                )}
                {!camposOcultar.includes('Serial') && (
                    <Grid item md={3} xs={12}>
                        <TextField
                            fullWidth
                            label={<Texto id="Documentos.CampoSerial" />}
                            onChange={(event) => manejarCambioFiltro('Serial', event.target.value)}
                            value={filtros.Serial}
                            inputProps={{ maxLength: 100 }}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Grid alignItems="center" container justifyContent="flex-end" spacing={2}>
                        <Grid item md="auto" xs={6}>
                            <BotonLimpiar fullWidth onClick={limpiarFiltros} />
                        </Grid>
                        <Grid item md="auto" xs={6}>
                            <BotonBuscar fullWidth onClick={aplicarFiltros} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
);

export default DocumentosFiltros;
