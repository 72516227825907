import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';

// ModalTraslado

const CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO: Array<keyof IDocumentoConsultaGeneral> = [
    'EtapaId',
    'CodigoDocumentoPredecesor',
    'CodigoDocumento',
    'DescipcionProveedorId',
];

const CAMPOS_DESHABILITAR_TABLA_INVENTARIO: Array<keyof IDocumentoDetalleConsultaGeneral> = [
    'PesoFraccion',
    'PesoFraccionRecibido',
    'PesoFraccionSolicitado',
    'CantidadEntregada',
];

const CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_DOCUMENTO_BASE: Array<keyof IDocumentoConsultaGeneral> = [
    ...CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
];

const CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION: Array<keyof IDocumentoConsultaGeneral> = [
    ...CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
    'TipoDocumentoId',
    'BodegaOrigen',
    'BodegaDestino',
    'ResponsableActualId',
    
];

const CAMPOS_EDITABLES_DETALLES_INVENTARIO: Array<keyof IDocumentoDetalleConsultaGeneral> = [
    'CantidadSolicitada',
    'CantidadRecibida',
    'DivisionDestino',
];

const CAMPOS_OCULTAR_DETALLES_INVENTARIO: Array<keyof IDocumentoDetalleConsultaGeneral> = [
    'CantidadSolicitada',
    'CantidadRecibida',
    'PesoDisponible',
    'PesoFraccion',
    'PesoFraccionRecibido',
    'CantidadEntregada'
];

const CAMPOS_OCULTAR_DETALLES_FORMULARIO: Array<keyof IDocumentoDetalle> = [
    'CantidadSolicitada',
    'CantidadRecibida',
    'Valor',
    'PesoFraccion',
    'PesoFraccionSolicitado',
   
    
];

export {
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_DOCUMENTO_BASE,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION,
    CAMPOS_EDITABLES_DETALLES_INVENTARIO,
    CAMPOS_OCULTAR_DETALLES_INVENTARIO,
    CAMPOS_OCULTAR_DETALLES_FORMULARIO,
    CAMPOS_DESHABILITAR_TABLA_INVENTARIO,
};
